// extracted by mini-css-extract-plugin
export var bannerSuper = "terms-conditions-module--bannerSuper--1r8r0";
export var button = "terms-conditions-module--button--dz-n5";
export var cASLOGOIcon = "terms-conditions-module--cASLOGOIcon--SH1Nb";
export var canadianSupply = "terms-conditions-module--canadianSupply--7R-et";
export var caption = "terms-conditions-module--caption--1lbe9";
export var caption23 = "terms-conditions-module--caption23--wZzMi";
export var ccs = "terms-conditions-module--ccs--5P0bq";
export var column = "terms-conditions-module--column--EuaIC";
export var contactUs = "terms-conditions-module--contactUs--9FHnS";
export var display = "terms-conditions-module--display--NTRsY";
export var display1 = "terms-conditions-module--display1--544Fe";
export var display3 = "terms-conditions-module--display3--avU8n";
export var docTemplate = "terms-conditions-module--docTemplate--9ATS2";
export var email = "terms-conditions-module--email--w6Mnn";
export var footer = "terms-conditions-module--footer--qypJv";
export var footerContent = "terms-conditions-module--footerContent--U1KZi";
export var footerL = "terms-conditions-module--footerL--WNSkU";
export var footerLinks = "terms-conditions-module--footerLinks--z-KQC";
export var footerR = "terms-conditions-module--footerR--sTTGp";
export var fullNavBar = "terms-conditions-module--fullNavBar--4EsQO";
export var iconButton = "terms-conditions-module--iconButton--ZhkNJ";
export var iconFilled = "terms-conditions-module--iconFilled--8ZX-S";
export var infowebsitecom = "terms-conditions-module--infowebsitecom---XwBb";
export var logo = "terms-conditions-module--logo--fN606";
export var logo1 = "terms-conditions-module--logo1--dncb+";
export var navbarContent = "terms-conditions-module--navbarContent--2HijP";
export var navbarContentMobile = "terms-conditions-module--navbarContentMobile--HoPhj";
export var navigation = "terms-conditions-module--navigation--ywhA-";
export var navigation1 = "terms-conditions-module--navigation1--mLvF2";
export var p = "terms-conditions-module--p--XpxRC";
export var p1 = "terms-conditions-module--p1--2SUFs";
export var p6 = "terms-conditions-module--p6--iCgen";
export var privacyPolicy = "terms-conditions-module--privacyPolicy--2BhIu";
export var privacyPolicy1 = "terms-conditions-module--privacyPolicy1--4glGT";
export var reproduceAndRepublish = "terms-conditions-module--reproduceAndRepublish--aw5Jd";
export var reproduceAndRepublishMateri = "terms-conditions-module--reproduceAndRepublishMateri--SsANP";
export var shippingPolicy = "terms-conditions-module--shippingPolicy--xz-DB";
export var subtitle = "terms-conditions-module--subtitle--fgIyr";
export var subtitleLarge = "terms-conditions-module--subtitleLarge--YRbLK";
export var termsConditions = "terms-conditions-module--termsConditions--llGUU";
export var textColumn = "terms-conditions-module--textColumn--ZQ-3J";
export var textbody = "terms-conditions-module--textbody---e1vf";
export var textbody5 = "terms-conditions-module--textbody5--ds0nt";
export var textbody7 = "terms-conditions-module--textbody7---qh5p";
export var thisAgreementShall = "terms-conditions-module--thisAgreementShall--Kwtni";
export var title = "terms-conditions-module--title--r9I7J";
export var visaIcon = "terms-conditions-module--visaIcon--uj71w";
export var youMustNot = "terms-conditions-module--youMustNot--omH7P";